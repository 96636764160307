<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="About us" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Title *"
                  v-model="title"
                  :error="!!errors.aboutus_title"
                  :error-messages="errors.aboutus_title"
                  @input="delete errors.aboutus_title"
                />
                <va-input
                  label="Subtitle"
                  v-model="subTitle"
                  :error="!!errors.aboutus_subtitle"
                  :error-messages="errors.aboutus_subtitle"
                  @input="delete errors.aboutus_subtitle"
                />
                <va-card class="mb-3" title="Image">
                  <div v-if="imageExisting">
                    <a style="display: block;" target="_blank" :href="imageExisting"><img style="max-width: 400px;" :src="imageExisting"></a>
                    <div><va-button small color="danger" @click="imageExisting = deleteImage(imageId, 'aboutus_image') ? '' : imageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.aboutus_image"
                    v-model="image"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('aboutus_image', 'aboutus_image', image[0])">Завантажити</va-button>
                  <div v-if="errors.aboutus_image" style="color: red;">{{ errors.aboutus_image[0] }}</div>
                </va-card>
                <va-input
                  label="Philosophy Title"
                  v-model="philosophyTitle"
                  :error="!!errors.aboutus_philosophy_title"
                  :error-messages="errors.aboutus_philosophy_title"
                  @input="delete errors.aboutus_philosophy_title"
                />
                <div style="margin-bottom: 15px;">
                  <span>Philosophy Description *</span>
                  <ckeditor v-model="philosophyDescription" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.aboutus_philosophy_description" style="color: red;">{{ errors.aboutus_philosophy_description[0] }}</div>
                </div>
                <va-card class="mb-3" title="Philosophy Image">
                  <div v-if="philosophyImageExisting">
                    <a style="display: block;" target="_blank" :href="philosophyImageExisting"><img style="max-width: 400px;" :src="philosophyImageExisting"></a>
                    <div><va-button small color="danger" @click="philosophyImageExisting = deleteImage(philosophyImageId, 'aboutus_philosophy_image') ? '' : philosophyImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.aboutus_philosophy_image"
                    v-model="philosophyImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('aboutus_philosophy_image', 'aboutus_philosophy_image', philosophyImage[0])">Завантажити</va-button>
                  <div v-if="errors.aboutus_philosophy_image" style="color: red;">{{ errors.aboutus_philosophy_image[0] }}</div>
                </va-card>
                <va-input
                  label="Strategy Title"
                  v-model="strategyTitle"
                  :error="!!errors.aboutus_strategy_title"
                  :error-messages="errors.aboutus_strategy_title"
                  @input="delete errors.aboutus_strategy_title"
                />
                <div style="margin-bottom: 15px;">
                  <span>Strategy Description *</span>
                  <ckeditor v-model="strategyDescription" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.aboutus_strategy_description" style="color: red;">{{ errors.aboutus_strategy_description[0] }}</div>
                </div>
                <va-card class="mb-3" title="Strategy Image">
                  <div v-if="strategyImageExisting">
                    <a style="display: block;" target="_blank" :href="strategyImageExisting"><img style="max-width: 400px;" :src="strategyImageExisting"></a>
                    <div><va-button small color="danger" @click="strategyImageExisting = deleteImage(strategyImageId, 'aboutus_strategy_image') ? '' : strategyImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.aboutus_strategy_image"
                    v-model="strategyImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('aboutus_strategy_image', 'aboutus_strategy_image', strategyImage[0])">Завантажити</va-button>
                  <div v-if="errors.aboutus_strategy_image" style="color: red;">{{ errors.aboutus_strategy_image[0] }}</div>
                </va-card>
                <va-card class="mb-3" title="Strategy Icon">
                  <div v-if="strategyIconExisting">
                    <a style="display: block;" target="_blank" :href="strategyIconExisting"><img style="max-width: 400px;" :src="strategyIconExisting"></a>
                    <div><va-button small color="danger" @click="strategyIconExisting = deleteImage(strategyIconId, 'aboutus_strategy_icon') ? '' : strategyIconExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.aboutus_strategy_icon"
                    v-model="strategyIcon"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('aboutus_strategy_icon', 'aboutus_strategy_icon', strategyIcon[0])">Завантажити</va-button>
                  <div v-if="errors.aboutus_strategy_icon" style="color: red;">{{ errors.aboutus_strategy_icon[0] }}</div>
                </va-card>
                <va-input
                  label="Team Title"
                  v-model="teamTitle"
                  :error="!!errors.aboutus_team_title"
                  :error-messages="errors.aboutus_team_title"
                  @input="delete errors.aboutus_team_title"
                />
                <div style="margin-bottom: 15px;">
                  <span>Team Description *</span>
                  <ckeditor v-model="teamDescription" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.aboutus_team_description" style="color: red;">{{ errors.aboutus_team_description[0] }}</div>
                </div>
                <va-card class="mb-3" title="Team Image">
                  <div v-if="teamImageExisting">
                    <a style="display: block;" target="_blank" :href="teamImageExisting"><img style="max-width: 400px;" :src="teamImageExisting"></a>
                    <div><va-button small color="danger" @click="teamImageExisting = deleteImage(teamImageId, 'aboutus_team_image') ? '' : teamImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.aboutus_team_image"
                    v-model="teamImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('aboutus_team_image', 'aboutus_team_image', teamImage[0])">Завантажити</va-button>
                  <div v-if="errors.aboutus_team_image" style="color: red;">{{ errors.aboutus_team_image[0] }}</div>
                </va-card>
                <va-card class="mb-3" title="Team Icon">
                  <div v-if="teamIconExisting">
                    <a style="display: block;" target="_blank" :href="teamIconExisting"><img style="max-width: 400px;" :src="teamIconExisting"></a>
                    <div><va-button small color="danger" @click="teamIconExisting = deleteImage(teamIconId, 'aboutus_team_icon') ? '' : teamIconExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.aboutus_team_icon"
                    v-model="teamIcon"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('aboutus_team_icon', 'aboutus_team_icon', teamIcon[0])">Завантажити</va-button>
                  <div v-if="errors.aboutus_team_image" style="color: red;">{{ errors.aboutus_team_image[0] }}</div>
                </va-card>
                <div style="margin-bottom: 15px;">
                  <span>Geography Description *</span>
                  <ckeditor v-model="geographyDescription" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.aboutus_geography_description" style="color: red;">{{ errors.aboutus_geography_description[0] }}</div>
                </div>
                <va-card class="mb-3" title="Geography Image">
                  <div v-if="geographyImageExisting">
                    <a style="display: block;" target="_blank" :href="geographyImageExisting"><img style="max-width: 400px;" :src="geographyImageExisting"></a>
                    <div><va-button small color="danger" @click="geographyImageExisting = deleteImage(geographyImageId, 'aboutus_geography_image') ? '' : geographyImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.aboutus_geography_image"
                    v-model="geographyImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('aboutus_geography_image', 'aboutus_geography_image', geographyImage[0])">Завантажити</va-button>
                  <div v-if="errors.aboutus_geography_image" style="color: red;">{{ errors.aboutus_geography_image[0] }}</div>
                </va-card>
                <va-toggle small label="Show partners" v-model="showPartners"></va-toggle>
                <va-input
                  label="SEO Title"
                  v-model="seoTitle"
                  :error="!!errors.aboutus_seo_title"
                  :error-messages="errors.aboutus_seo_title"
                  @input="delete errors.aboutus_seo_title"
                />
                <va-input
                  label="SEO Description"
                  type="textarea"
                  v-model="seoDescription"
                  :error="!!errors.aboutus_seo_description"
                  :error-messages="errors.aboutus_seo_description"
                  @input="delete errors.aboutus_seo_description"
                />
                <va-input
                  label="SEO OpenGraph Title"
                  v-model="seoOpenGraphTitle"
                  :error="!!errors.aboutus_seo_ogtitle"
                  :error-messages="errors.aboutus_seo_ogtitle"
                  @input="delete errors.aboutus_seo_ogtitle"
                />
                <va-input
                  type="textarea"
                  label="SEO OpenGraph Description"
                  v-model="seoOpenGraphDescription"
                  :error="!!errors.aboutus_seo_ogdescription"
                  :error-messages="errors.aboutus_seo_ogdescription"
                  @input="delete errors.aboutus_seo_ogdescription"
                />
                <va-card class="mb-3" title="SEO OpenGraph Image">
                  <div v-if="seoOpenGraphImageExisting">
                    <a style="display: block;" target="_blank" :href="seoOpenGraphImageExisting"><img style="max-width: 400px;" :src="seoOpenGraphImageExisting"></a>
                    <div><va-button small color="danger" @click="seoOpenGraphImageExisting = deleteImage(seoOpenGraphImageId, 'aboutus_seo_ogimage') ? '' : seoOpenGraphImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.aboutus_seo_ogimage"
                    v-model="seoOpenGraphImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('aboutus_seo_ogimage', 'aboutus_seo_ogimage', seoOpenGraphImage[0])">Завантажити</va-button>
                  <div v-if="errors.aboutus_seo_ogimage" style="color: red;">{{ errors.aboutus_seo_ogimage[0] }}</div>
                </va-card>
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      title: '',
      subTitle: '',
      image: [],
      imageId: '',
      imageExisting: '',
      philosophyTitle: '',
      philosophyDescription: '',
      philosophyImage: [],
      philosophyImageId: '',
      philosophyImageExisting: '',
      strategyTitle: '',
      strategyDescription: '',
      strategyImage: [],
      strategyImageId: '',
      strategyImageExisting: '',
      strategyIcon: [],
      strategyIconId: '',
      strategyIconExisting: '',
      teamTitle: '',
      teamDescription: '',
      teamImage: [],
      teamImageId: '',
      teamImageExisting: '',
      teamIcon: [],
      teamIconId: '',
      teamIconExisting: '',
      geographyDescription: '',
      geographyImage: [],
      geographyImageId: '',
      geographyImageExisting: '',
      showPartners: false,

      seoTitle: '',
      seoDescription: '',
      seoOpenGraphTitle: '',
      seoOpenGraphDescription: '',
      seoOpenGraphImage: [],
      seoOpenGraphImageId: '',
      seoOpenGraphImageExisting: '',

      errors: [],

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_AGRO_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        aboutus_title: this.title,
        aboutus_subtitle: this.subTitle,

        aboutus_philosophy_title: this.philosophyTitle,
        aboutus_philosophy_description: this.philosophyDescription,

        aboutus_strategy_title: this.strategyTitle,
        aboutus_strategy_description: this.strategyDescription,

        aboutus_team_title: this.teamTitle,
        aboutus_team_description: this.teamDescription,

        aboutus_geography_description: this.geographyDescription,

        aboutus_partners_show: this.showPartners ? 1 : 0,

        aboutus_seo_title: this.seoTitle,
        aboutus_seo_description: this.seoDescription,
        aboutus_seo_ogtitle: this.seoOpenGraphTitle,
        aboutus_seo_ogdescription: this.seoOpenGraphDescription,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (param, key, file, agg = 'about-us') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append(param, file)
      formData.append('lang', this.locale)
      formData.append('aggregate', agg)
      formData.append('key', key)
      formData.append('multiple', '0')
      formData.append('file_id', '0')
      axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/upload/2?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id, fieldKey) {
      return axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/2/delete-file/${id}?lang=${this.locale}`, {
        key: fieldKey,
      })
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/2?lang=${this.locale}`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/validate/2?lang=${this.locale}`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AGRO_API_URL}/admin/pages/2?lang=${this.locale}`)
        .then(response => {
          this.title = response.data.aboutus_title
          this.subTitle = response.data.aboutus_subtitle
          this.imageExisting = response.data.aboutus_image ? process.env.VUE_APP_AGRO_ADMIN + response.data.aboutus_image : null
          this.imageId = response.data.aboutus_image_id

          this.philosophyTitle = response.data.aboutus_philosophy_title
          this.philosophyDescription = response.data.aboutus_philosophy_description
          this.philosophyImageExisting = response.data.aboutus_philosophy_image ? process.env.VUE_APP_AGRO_ADMIN + response.data.aboutus_philosophy_image : null
          this.philosophyImageId = response.data.aboutus_philosophy_image_id

          this.strategyTitle = response.data.aboutus_strategy_title
          this.strategyDescription = response.data.aboutus_strategy_description
          this.strategyImageExisting = response.data.aboutus_strategy_image ? process.env.VUE_APP_AGRO_ADMIN + response.data.aboutus_strategy_image : null
          this.strategyImageId = response.data.aboutus_strategy_image_id
          this.strategyIconExisting = response.data.aboutus_strategy_icon ? process.env.VUE_APP_AGRO_ADMIN + response.data.aboutus_strategy_icon : null
          this.strategyIconId = response.data.aboutus_strategy_icon_id

          this.teamTitle = response.data.aboutus_team_title
          this.teamDescription = response.data.aboutus_team_description
          this.teamImageExisting = response.data.aboutus_team_image ? process.env.VUE_APP_AGRO_ADMIN + response.data.aboutus_team_image : null
          this.teamImageId = response.data.aboutus_team_image_id
          this.teamIconExisting = response.data.aboutus_team_icon ? process.env.VUE_APP_AGRO_ADMIN + response.data.aboutus_team_icon : null
          this.teamIconId = response.data.aboutus_team_icon_id

          this.geographyDescription = response.data.aboutus_geography_description
          this.geographyImageExisting = response.data.aboutus_geography_image ? process.env.VUE_APP_AGRO_ADMIN + response.data.aboutus_geography_image : null
          this.geographyImageId = response.data.aboutus_geography_image_id

          this.showPartners = response.data.aboutus_partners_show === '1'

          this.seoTitle = response.data.aboutus_seo_title
          this.seoDescription = response.data.aboutus_seo_description
          this.seoOpenGraphTitle = response.data.aboutus_seo_ogtitle
          this.seoOpenGraphDescription = response.data.aboutus_seo_ogdescription
          this.seoOpenGraphImageExisting = response.data.aboutus_seo_ogimage ? process.env.VUE_APP_AGRO_ADMIN + response.data.aboutus_seo_ogimage : null
          this.seoOpenGraphImageId = response.data.aboutus_seo_ogimage_id
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
